import type { IEntity } from "~/layers/company/interfaces/iEntity"
import type { ISettingOption } from "~/layers/company/interfaces/iSettingOption"

interface State {
  companies: IEntity[]
  companySettings: ISettingOption[]
}
export const useAuthStore = defineStore("auth", {
  state: () => ({
    companies: [],
    companySettings: [],
  }),
})
